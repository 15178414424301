.budgetItems{
    height: 62.5%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2.5%;
    margin-bottom: 5%;
    overflow-y: scroll;
}

.budgetItem{
    background-color: #343d46;
    height: 25%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5%;
    border-radius: 10px;
    justify-content: left;
}
.budgetItem .title{
    height: 33%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.budgetItem .title h3{
    text-overflow: ellipsis;
    display: flexbox;
    overflow: hidden;
    white-space: nowrap;          /* Prevent text from wrapping to the next line */
    justify-content: center;
    text-align: center;
}
.budgetItem img{
    height: 75%;
    border-radius: 50%;
    width: auto;
    margin: 2.5%;
}

.budgetItem p{
    line-height:130%;
    white-space: nowrap;          /* Prevent text from wrapping to the next line */
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 2%;
    margin-right: 2%;
}

