.nav-container {
  /* height: 9vh; */
  width: 100vw;
  /* background-color: #de8149; */
  background-color: rgb(224, 57, 25);
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  /* border-bottom: 5px solid #344557; */
}

.pageLink {
  margin: 10px;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  text-decoration: none;
  color: white;
  font-size: 1.7rem;
}

.pageLink-highlighted {
  margin: 10px;
  font-family:'Helvetica Neue';
  font-weight: 700;
  text-decoration: none;
  color: white;
  font-size: 1.7rem;
  text-shadow: black 1px 0 10px;
}

.seds-logo {
  height: 7vh;
  width: auto;
  position: absolute;
  margin: 2px;
  left: 0;
  top: 0;
}