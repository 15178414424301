.accountInfo{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 50%;
    margin: 1%;
    width: 95%;
}

.accountInfo h1{
    margin: 0;
}