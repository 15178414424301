.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: 	#343d46;
  width: 100vw;
}

.about-container {
  display: grid;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  grid-template-columns: repeat(2, 3fr);
  gap: 20px;
}

.about-header {
  color: #de8149;
  font-family: Helvetica;
  text-align: center;
  font-size: 5rem;
  font-weight: 800;
  margin: 0px;
}

.about-text-blocks {
  color: #FBE0C5;
  font-family: Helvetica;
  text-align: center;
  width: 30vw;
  font-weight: 700;
}

.seds-group {
  height: 45vh;
  width: 30vw;
  border-radius: 15px;
  margin-bottom: 40px;
}

.seds-rover {
  height: 35vh;
  width: 25vw;
  border-radius: 15px;
}

.seds-station {
  height: 50vh;
  width: 33vw;
  border-radius: 15px;
}

.seds-red {
  height: 30vh;
}

.row {
  display: flex;
  flex-direction: row;
}

.project-container {
  width: 45vw;
  height: 35vh;
  background-color: #ca6d3c;
  border-radius: 25px;
  font-size: 1.5rem;
  margin: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.project-container p {
  color: rgb(222, 207, 207);
  font-size: .75rem;
  margin: 0;
  font-weight: 800;
}

.project-container h1 {
  margin: 0;
  color: #263748;
}
