.myItems{
    height: 47.5%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    overflow-y: scroll;
}

.myItem{
    background-color: #343d46;
    height: 20%;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2.5%;
    border-radius: 10px;
    justify-content: left;
}

.myItem img{
    height: 50%;
    width: auto;
    margin-left: 2.5%;
}

.textContent{
    width: 90%;
    text-align: center;
    justify-content: center;

}
.textContent h3{
    white-space: nowrap;          /* Prevent text from wrapping to the next line */
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
}
.textContent p{
    margin-top: 0px;
}